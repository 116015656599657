body {
  background-color: #222222;
  color: #cccccc;
  font-family: -apple-system, BlinkMacSystemFont, 'Titillium Web', 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding: 0px 5px 5px 5px;
  font-family: 'Titillium Web', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #ffffff;
}

a:hover {
  color: #ffffff;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
}

/* Bootstrap overrides */

.card {
  background-color: #303030;
  border-radius: 1rem;
}

.card-header {
  font-size: 1.2rem;
  font-weight: bolder;
  text-align: center;
}

.card-title {
  font-size: 1rem;
    text-align: center;
}

.card-subtitle {
  font-size: 0.9rem;
  text-align: center;
}

.card-text {
  font-size: .8rem;
}

.text-muted {
  color: #ffffff !important;
}

.card-footer {
  padding: 8px 4px 8px 35px;
}

.mr-auto, .mx-auto {
    font-size: 0.8em;
}

.spinner-border {
    float: right;
    width: 1.4rem;
    height: 1.4rem;
}

.input-group-text {
  color: #cccccc;
  background-color: #222222;
  border-color: #222222;
}

.container {
  padding: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.col {
  padding-right: 0;
  padding-left: 0;
}

/* Header elements */

.logo-header {
  float: left;
  transition: 0.5s;
  transform: scale(1);
}

.logo-header:hover {
  transition: 0.5s;
  transform: scale(0.6);
}

.connect-button {
  border-radius: 18px;
}

.address-button {
  border-radius: 18px;
}

.connected-address {
  border-radius: 18px;
  background-color: #009966;
  color: #ffffff;
  padding: 7px 16px 7px 16px;
}

.lync-price {
  border-radius: 18px;
  border-style: solid;
  border-width: thin;
  border-color: #006DB8;
  background-color: #333333;
  color: #eeeeee;
  padding: 7px 16px 7px 16px;
}

.lync-balance {
  border-radius: 18px;
  border-style: solid;
  border-width: thin;
  border-color: #009966;
  background-color: #333333;
  color: #eeeeee;
  padding: 7px 16px 7px 16px;
}

.wrong-network {
  border-radius: 12px;
  background-color: #FF8C00;
  color: #ffffff;
  padding: 6px 12px 6px 12px;
}

.navbar-nav {
  margin-top: 4px;
  text-align: center;
}

/* Navigation elements */

.nav-pills .nav-link {
  background-color: #343A40;
  color: #CCCCCC;
  border-radius: 0.5rem;
}

.nav-pills .nav-item {
 margin-right: 5px;
 margin-top: 5px;
}

.nav-pills .nav-link.active {
  background-color: #28A745;
}

.nav-link.disabled {
  color: #777777;
  background-color: #303030;
}

.card-crafters .nav-link {
  background-color: #B22222;
  color: #ffffff;
}

/* Staking elements */

.staking-subheader {
  width: 584px;
  font-family: monospace;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #343A40;
  border-radius: 0.5rem;
  margin-top: 5px;
  padding-left: 16px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.staking-reward-pool-notice {
  font-size: 0.5em;
}

.staking-approval {
  width: 584px;
  height: 52px;
  font-family: monospace;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #343A40;
  border-radius: 0.5rem;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.staking-single {
  width: 290px;
  height: 200px;
  text-align: center;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

.staking-approval-button {
  float: left;
  margin-right: 10px;
}

.staking-approval-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 40px;
}

.staking-countdown {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  border-radius: 8px;
  border-style: solid;
  border-width: thin;
  border-color: #dc3545;
  background-color: #222222;
  color: #ffffff;
  padding: 6px 12px 6px 12px;
}

/* NFT and wallet elements */

.nft-subheader {
  width: 645px;
  font-family: monospace;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #343A40;
  border-radius: 0.5rem;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.nft-no-cards {
  width: 645px;
  font-family: monospace;
  font-size: 1.4em;
  font-weight: 400;
  background-color: #8B0000;
  border-radius: 0.5rem;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.loading-spinner {
  padding: 15px;

}

.nft-single {
  width: 320px;
  height: 740px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

.nft-image img {
  width: 225px;
  height: 300px;
}

.card-header:first-child {
  font-family: monospace;
  font-size: 1.5em;
  background-color: #343A40;
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.nft-image {
  margin: 0 auto;
  width: 225px;
  height: 300px;
  margin-bottom: 4px;
}

.nft-balance {
  width: 0;
  height: 0;
  border-top: 100px solid #ffcc00;
  border-right: 100px solid transparent;
  position:absolute;
}

.nft-balance span {
  position:absolute;
  top: -85px;
  width: 100px;
  left: -20px;
  text-align: center;
  font-family: monospace;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  transform: rotate(-45deg);
  display:block;
}

.nft-description {
  text-align: justify;
  font-style: italic;
  height: 60px;
}

.nft-fetching {
  text-align: center;
  color: #6c757d;
}

.nft-break-line {
  width: 60%;
  border-top: 1px solid #6c757d;
}

.nft-redeem-green {
  background-color:rgba(0, 200, 0, 0.2);
  line-height: 0.2em;
}

.nft-redeem-red {
  background-color:rgba(200, 0, 0, 0.2);
  line-height: 0.2em;
}

.nft-redeem-red::before {
  content: "\1F552  ";
}

.nft-table-row {
  line-height: 0.2em;
}

.nft-boost-form {
  color: #CCCCCC;
  background-color: #262626;
  text-align: left;
  margin-bottom: 0;
  font-size: 0.8em;
  padding-left: 12px;
}

.nft-boost::after {
  content: "  \1F525 ";
}

.table-dark {
  color: #CCCCCC;
  background-color: #262626;
  text-align: left;
  margin-bottom: 0;
  font-size: 0.8em;
}

.nft-data-background {
  height: 140px;
  background: url("/lynclogo_grayscale.png") no-repeat center;
}

.card-footer:last-child {
  text-align: center;
  background-color: #111111;
  border-radius: 0 0 calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.single-nft-links {
  float: right;
}

.single-nft-links img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Cardchecker elements */

.checker {
  width: 584px;
  height: 446px;
  margin-top: 5px;
  background-color: #303030;
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.checker-nft-single {
  width: 320px;
  height: 740px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

.checker-nft-checked {
  background-color: #303030;
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.checker-nft-image img {
  width: 225px;
  height: 300px;
}

.checker-card-header:first-child {
  font-family: monospace;
  font-size: 1.5em;
  background-color: #343A40;
  border-radius: calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px);
}

.checker-nft-image {
  margin-top: 1rem;
  text-align: center;
}

.checker-frame {
  height: 54px;
  padding: 8px;
}

.checker-card-body {
  padding: 1rem 1rem 0rem 0rem;
}

.checker-form {
  margin-top: 4px;
  margin-left: 8px;
}

.checker-button {
  float: left;
}

.checker-links {
  float: right;
  margin-top: -5px;
  margin-right: -15px;
}

.checker-links img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Crafters elements */

.crafters-subheader {
  width: 584px;
  font-family: monospace;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #343A40;
  border-radius: 0.5rem;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.crafter-single {
  width: 400px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

.card-crafter-description {
  height: 80px;
}

.form-group {
  line-height: .2rem;
}

.form-control {
	width: 100%;
	color: #ffffff;
	background-color: #444444;
	background-clip: padding-box;
	border: 1px solid #333;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control:focus {
	color: #ffffff;
	background-color: #444444;
	border-color: #008000;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(55, 90, 127, .25)
}

/* Main body elements */

.home {
  margin: 10px;
}

.user-guides {
  margin-top: 5px;
}

.user-guides-green {
  font-weight: 600;
  color: #28A745;
}

.user-guides-red {
  font-weight: 600;
  color: #dc3545;
}

.user-guides-blue {
  font-weight: 600;
  color: #00AEEF;
}

.user-guides-purple {
  font-weight: 600;
  color: #CC00FF;
}

.user-guides-boost::after {
  content: "  \1F525 ";
}


/* Custom elements */

.centered-submit-buttons {
  text-align: center;
}

/* Errors  */

.error404 {
  padding: 10px;
}

/* Responsive elements */

@media only screen and (max-width: 991px) {

  .navbar-nav {
    text-align: center;
    display: block;
  }

  .connected-address {
    margin-right: 0;
  }

  .col-lg-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }
}

@media only screen and (max-width: 676px) {

  .nft-single, .nft-no-cards, .nft-subheader {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {

  .crafters-subheader, .nft-subheader, .staking-subheader, .staking-approval {
    width: 100%;
  }

  .staking-single {
    width: 100%;
    height: 180px;
  }

  .navbar-nav {
    text-align: center;
    display: block;
  }

  .checker {
    width: 100%;
  }

  .form-row {
    width: 60%;
  }

  .checker-card-body {
    padding: 1rem 1rem 1rem 1rem;
  }

  .checker .row {
    display: block;
  }
}

@media only screen and (min-width: 576px) {

  .container {
    max-width: 720px;
  }
}
